import { Box, Button, Tooltip } from '@mui/material';

export type SongShowButtonProps = {
  isVideoShown: boolean;
  toggleVideoShown: () => void;
};
export function VideoShowButton({ isVideoShown, toggleVideoShown }: SongShowButtonProps) {
  return (
    <Box>
      <Tooltip title={isVideoShown ? 'Hide video area' : 'Show video area'}>
        <Button
          sx={{
            width: '70px',
            height: '28px',
            weight: '400',
            lineHeight: '18px',
            boxShadow: 'none',
            ...(!isVideoShown && {
              opacity: 0.5,
              alignItems: 'end'
            })
          }}
          onClick={toggleVideoShown}
          size="small"
          disableElevation={true}
          variant="contained"
          color="secondary"
        >
          video
          {isVideoShown ? (
            <img style={{ marginLeft: 7 }} src="/assets/opened-eye.svg" alt="closed eye" />
          ) : (
            <img style={{ marginLeft: 7 }} src="/assets/closed-eye.svg" alt="closed eye" />
          )}
        </Button>
      </Tooltip>
    </Box>
  );
}
