import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { UploadArea } from '../../../components/UploadArea';
import { getFile } from '../../../utils/fileUtils';
import { ReactComponent as ImageIcon } from '../../../assets/icons/imageIcon.svg';

type CancelDownloadingDialog = {
  onClose: () => void;
  onCreate: (image: File) => void;
  open: boolean;
  isAlbum: boolean;
};

export function AddArtDialog({ onClose, onCreate, open, isAlbum }: CancelDownloadingDialog) {
  const [file, setFile] = useState<File | undefined>();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogCloseButton onClose={onClose} />
      <DialogTitle>Upload {isAlbum ? 'Album' : 'Cover'} Art</DialogTitle>
      <DialogContent dividers>
        {file ? (
          <Box
            sx={{
              width: '100%',
              height: '207px',
              background: `url(${URL.createObjectURL(file)}) center/cover no-repeat`
            }}
          />
        ) : (
          <Box
            onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
              e.preventDefault();

              const items = e.dataTransfer.items;
              const fileSystemEntries: FileSystemEntry[] = [];

              Array.from(items).forEach(file => {
                const entry = file.webkitGetAsEntry();

                if (entry) {
                  fileSystemEntries.push(entry);
                }
              });

              const image = await getFile(fileSystemEntries.at(0) as FileSystemFileEntry);

              if (image.type.includes('image/')) {
                setFile(image);
              }
            }}
            onDragOver={e => {
              e.preventDefault();
            }}
            borderRadius={1}
            sx={{
              width: '100%',
              height: '207px',
              background: 'rgba(75, 75, 75, 0.4)',
              border: '1px dashed rgba(171, 171, 171, 1)'
            }}
          >
            <Box component={'label'}>
              <Stack height={1} width={1} justifyContent={'center'} alignItems={'center'} gap={2.5}>
                <ImageIcon />
                <Typography fontSize={14} fontWeight={400}>
                  Drop or <span style={{ color: 'rgba(0, 142, 243, 1)' }}>upload</span> Cover Art here
                </Typography>
              </Stack>
              <UploadArea
                acceptAll={true}
                onUpload={files => {
                  const file = files.item(0);

                  if (file && file.type.includes('image/')) {
                    setFile(file);
                  }
                }}
                multiple={false}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (file) {
              onCreate(file);
              onClose();
            }
          }}
          size="small"
          type="submit"
          color="info"
          variant="contained"
          disabled={!file}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
