import { MetadataBitDepthValues, MetadataSampleRateValues, MetadataType, MetadataVocals, SongMetadata } from '../@types/songMetadata';
import axios from '../utils/axios';

const getFullMetadata = async (versionId: string) => {
  return axios.get<SongMetadata>(`songMetadata/${versionId}/getFullMetadata`).then(res => res.data);
};

const addAlbumTitle = async (versionId: string, albumTitle: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addAlbumTitle`, { albumTitle }).then(res => res.data);
};

const addGenre = async (versionId: string, genre: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addGenre`, { genre }).then(res => res.data);
};

const addArtist = async (versionId: string, name: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addArtist`, { name }).then(res => res.data);
};

const addPublisher = async (versionId: string, name: string, ownershipRate: number) => {
  return axios.post<string>(`songMetadata/${versionId}/addPublisher`, { name, ownershipRate }).then(res => res.data);
};

const addComposer = async (versionId: string, name: string, ownershipRate: number) => {
  return axios.post<string>(`songMetadata/${versionId}/addComposer`, { name, ownershipRate }).then(res => res.data);
};

const addISRCCode = async (versionId: string, code: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addISRCCode`, { code }).then(res => res.data);
};

const addUPCCode = async (versionId: string, code: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addUPCCode`, { code }).then(res => res.data);
};

const addKeyword = async (versionId: string, keyword: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addKeyword`, { keyWord: keyword }).then(res => res.data);
};

const addInstrument = async (versionId: string, instrument: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addInstrument`, { instrument }).then(res => res.data);
};

const addMood = async (versionId: string, mood: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addMood`, { mood }).then(res => res.data);
};

const addEditLyrics = async (versionId: string, lyrics: string) => {
  return axios.post<string>(`songMetadata/${versionId}/addEditLyrics`, { lyrics }).then(res => res.data);
};

const deleteAlbumTitle = async (albumTitleId: string) => {
  return axios.delete(`songMetadata/deleteAlbumTitle/${albumTitleId}`);
};

const deleteGenre = async (genreId: string) => {
  return axios.delete(`songMetadata/deleteGenre/${genreId}`);
};

const deleteArtist = async (artistId: string) => {
  return axios.delete(`songMetadata/deleteArtist/${artistId}`);
};

const deleteComposer = async (composerId: string) => {
  return axios.delete(`songMetadata/deleteComposer/${composerId}`);
};

const deletePublisher = async (publisherId: string) => {
  return axios.delete(`songMetadata/deletePublisher/${publisherId}`);
};

const deleteISRCCode = async (ISRCCodeId: string) => {
  return axios.delete(`songMetadata/deleteISRCCode/${ISRCCodeId}`);
};

const deleteUPCCode = async (UPCCodeId: string) => {
  return axios.delete(`songMetadata/deleteUPCCode/${UPCCodeId}`);
};

const deleteKeyword = async (keywordId: string) => {
  return axios.delete(`songMetadata/deleteKeyword/${keywordId}`);
};

const deleteInstrument = async (instrumentId: string) => {
  return axios.delete(`songMetadata/deleteInstrument/${instrumentId}`);
};

const deleteMood = async (moodId: string) => {
  return axios.delete(`songMetadata/deleteMood/${moodId}`);
};

const deleteLyrics = async (lyricsId: string) => {
  return axios.delete(`songMetadata/deleteLyrics/${lyricsId}`);
};

const changeSampleRate = async (versionId: string, sampleRate: MetadataSampleRateValues) => {
  return axios.post<string>(`songMetadata/${versionId}/change-sample-rate`, { sampleRate }).then(res => res.data);
};

const changeBitDepth = async (versionId: string, bitDepth: MetadataBitDepthValues) => {
  return axios.post<string>(`songMetadata/${versionId}/change-bit-depth`, { bitDepth }).then(res => res.data);
};

const changeVocals = async (versionId: string, vocals: MetadataVocals) => {
  return axios.post<string>(`songMetadata/${versionId}/change-vocals`, { vocals }).then(res => res.data);
};

const changeType = async (versionId: string, type: MetadataType) => {
  return axios.post<string>(`songMetadata/${versionId}/change-type`, { type }).then(res => res.data);
};

const editDescription = async (versionId: string, description: string) => {
  return axios.post<string>(`songMetadata/${versionId}/edit-description`, { description }).then(res => res.data);
};
const saveMetadata = async (versionId: string) => {
  return axios.post<string>(`songMetadata/updateFilesWithMetadata`, { versionId }).then(res => res.data);
};

const songMetadataService = {
  addAlbumTitle,
  addGenre,
  addArtist,
  addComposer,
  addISRCCode,
  addUPCCode,
  addEditLyrics,
  deleteAlbumTitle,
  deleteGenre,
  deleteArtist,
  deleteComposer,
  deleteISRCCode,
  deleteUPCCode,
  deleteLyrics,
  addPublisher,
  deletePublisher,
  getFullMetadata,
  changeSampleRate,
  changeBitDepth,
  saveMetadata,
  addKeyword,
  addInstrument,
  addMood,
  deleteKeyword,
  deleteInstrument,
  deleteMood,
  changeVocals,
  changeType,
  editDescription
};

export default songMetadataService;
