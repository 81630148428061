import EventEmitter from 'events';
import { v4 as uuidv4 } from 'uuid';
import WaveSurfer from 'wavesurfer.js';
import { create } from 'zustand';
import { MetadataBitDepthValues, MetadataSampleRateValues, MetadataType, MetadataVocals, SongMetadata } from '../@types/songMetadata';
import { SoloMutedStems } from '../@types/songs';

type UpdateOutputFunction = (
  output: SongMetadata & { key: string; bpm: string },
  input: any
) => SongMetadata & { key: string; bpm: string };
const updateSongMetadata: UpdateOutputFunction = (output, input) => {
  // Normalize input if it looks like LIST metadata
  const isListMetadata =
    input.IART || input.INAM || input.IPRD || input.IGNR || input.ILYR || input.ISRC || input.IUPC || input.IKEY || input.TBPM;
  if (isListMetadata) {
    // Map LIST fields to input fields used by ID3 logic
    // This is a simple mapping; adjust as needed based on what you store.
    input.album = input.IPRD || input.album;
    input.genre = input.IGNR || input.genre;
    input.artist = input.IART || input.artist;
    input.composer = input.ICMP || input.composer;
    input.publisher = input.IPUB || input.publisher;
    input.isrc = input.ISRC || input.isrc;
    input.bpm = input.TBPM || input.bpm;

    // For UPC and Key, we previously relied on user-defined-text-information in ID3.
    // In LIST metadata, these are given directly as IUPC and IKEY.
    // We'll mimic user-defined-text-information structure for consistency.
    const userDefined = [];
    if (input.IUPC) {
      userDefined.push({ description: 'UPC', value: input.IUPC });
    }
    if (input.IKEY) {
      userDefined.push({ description: 'Key', value: input.IKEY });
    }
    if (userDefined.length > 0) {
      input['user-defined-text-information'] = userDefined;
    }

    // If there's lyrics
    // In ID3: lyrics go into a different frame, but here we just treat them as a string.
    // We'll assign it to a field that your logic can handle if needed.
    // If you previously handled lyrics from a different ID3 field, you can adapt similarly.
    if (input.ILYR) {
      // Just map directly, assuming your code uses input.lyrics or so:
      // If you don't handle lyrics directly, you can skip this.
      input.lyrics = input.ILYR;
    }
  }

  // Helper function to avoid duplicates in arrays
  const addUnique = <T>(array: T[], item: T, compareKey: keyof T): void => {
    if (!array.some(existingItem => existingItem[compareKey] === item[compareKey])) {
      array.push(item);
    }
  };

  // Now proceed as before, using input.album, input.genre, etc.

  // Update albumTitles
  if (input.album) {
    addUnique(output.albumTitles, { id: uuidv4(), albumTitle: input.album }, 'albumTitle');
  }

  // Update genres
  if (input.genre) {
    addUnique(output.genres, { id: uuidv4(), genre: input.genre }, 'genre');
  }

  // Update artists
  if (input.artist) {
    addUnique(output.artists, { id: uuidv4(), name: input.artist }, 'name');
  }

  // Update composers
  if (input.composer) {
    addUnique(output.composers, { id: uuidv4(), name: input.composer, ownershipRate: 0 }, 'name');
  }

  // Update publishers
  if (input.publisher) {
    addUnique(output.publishers, { id: uuidv4(), name: input.publisher, ownershipRate: 0 }, 'name');
  }

  // Update ISRCCodes
  if (input.isrc) {
    addUnique(output.ISRCCodes, { id: uuidv4(), code: input.isrc }, 'code');
  }

  // Update BPM
  if (input.bpm) {
    output.bpm = input.bpm;
  }
  if (input.lyrics) {
    addUnique(output.lyrics, { id: uuidv4(), lyrics: input.lyrics }, 'lyrics');
  }

  // Update UPCCodes and Key from user-defined-text-information (ID3 or simulated)
  if (input['user-defined-text-information']) {
    input['user-defined-text-information'].forEach((info: any) => {
      if (info.description === 'UPC' && info.value) {
        addUnique(output.UPCCodes, { id: uuidv4(), code: info.value }, 'code');
      }
      if (info.description === 'Key' && info.value) {
        output.key = info.value;
      }
      if (info.description === 'Lyrics' && info.value) {
        addUnique(output.lyrics, { id: uuidv4(), lyrics: info.value }, 'lyrics');
      }
      if (info.description === 'sampleRate' && info.value) {
        output.sampleRate = info.value;
      }
      if (info.description === 'bitDepth' && info.value) {
        output.bitDepth = info.value;
      }
    });
  }
  if (input.sampleRate) {
    output.sampleRate = input.sampleRate;
  }
  if (input.bitDepth) {
    output.bitDepth = input.bitDepth;
  }
  return output;
};
type PlaylistStatePerSong = {
  eventEmitter: EventEmitter;
  playlist: any;
  waveSurfer: WaveSurfer | null;
  loadedStemsCount: number;
  stemsCountForLoading: number;
  isSongRendered: boolean;
  isStemsRendered: boolean;
  songId: string;
  versionId: string;
  soloMutedStems: Map<string, SoloMutedStems> | null;
  songUrl: string;
  fileMetadata: SongMetadata & { key: string; bpm: string };
};
type PlaylistState = { playListStates: PlaylistStatePerSong[] };
type PlaylistActions = {
  init: (versionId: string) => PlaylistStatePerSong | null | undefined;
  initPlaylist: (playlist: any, versionId: string) => void;
  initWavesurfer: (waveSurfer: WaveSurfer | null, songUrl: string, versionId: string) => void;
  clearLoadedStemsCount: (versionId: string) => void;
  incrLoadedStemsCount: (versionId: string) => void;
  setStemsCountForLoading: (stemsCount: number, versionId: string) => void;
  handleIsSongRendered: (isSongRendered: boolean, versionId: string) => void;
  handleIsStemsRendered: (isStemsRendered: boolean, versionId: string) => void;
  setSoloMutedStems: (soloMutedStems: Map<string, SoloMutedStems>, versionId: string) => void;
  clearStemPlayers: () => void;
  setFileMetadata: (fileMetadata: any, versionId: string) => void;
};

export const usePlaylistStore = create<PlaylistState & PlaylistActions>((set, get) => ({
  playListStates: [],
  handleIsSongRendered: (isSongRendered, versionId) =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, isSongRendered };
          }
          return state;
        })
      ]
    }),
  setFileMetadata: (metadata, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            let adjustedMetadata = updateSongMetadata(state.fileMetadata, metadata);
            if (adjustedMetadata.sampleRate)
              adjustedMetadata.sampleRate = (parseInt(adjustedMetadata.sampleRate as unknown as string) / 1000) as MetadataSampleRateValues;
            if (adjustedMetadata.bitDepth)
              adjustedMetadata.bitDepth = parseInt(adjustedMetadata.bitDepth as unknown as string) as MetadataBitDepthValues;
            return { ...state, fileMetadata: { ...adjustedMetadata } };
          }
          return state;
        })
      ]
    });
  },
  handleIsStemsRendered: (isStemsRendered, versionId) =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, isStemsRendered };
          }
          return state;
        })
      ]
    }),
  clearLoadedStemsCount: versionId =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, loadedStemsCount: 0, stemsCountForLoading: 0, isStemsRendered: false };
          }
          return state;
        })
      ]
    }),
  incrLoadedStemsCount: (versionId: string) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return {
              ...state,
              isStemsRendered: state.loadedStemsCount + 1 >= state.stemsCountForLoading,
              loadedStemsCount: state.loadedStemsCount + 1
            };
          }
          return state;
        })
      ]
    });
  },
  setStemsCountForLoading: (stemsCount, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, stemsCountForLoading: stemsCount };
          }
          return state;
        })
      ]
    });
  },
  setSoloMutedStems: (soloMutedStems, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, soloMutedStems };
          }
          return state;
        })
      ]
    });
  },
  clearStemPlayers: () => {
    get().playListStates.forEach(state => {
      state.eventEmitter.emit('clear');
      state.eventEmitter.removeAllListeners();
      state.playlist = null;
      state.loadedStemsCount = 0;
      state.stemsCountForLoading = 0;
      state.isStemsRendered = false;
    });
  },
  init: versionId => {
    if (versionId && !get().playListStates.find(state => state.versionId === versionId)) {
      const newState = [
        ...get().playListStates,
        {
          eventEmitter: new EventEmitter(),
          playlist: null,
          waveSurfer: null,
          totalStemsCount: 0,
          loadedStemsCount: 0,
          stemsCountForLoading: 0,
          isSongRendered: false,
          isStemsRendered: false,
          songId: '',
          versionId,
          soloMutedStems: null,
          songUrl: '',
          fileMetadata: {
            albumTitles: [],
            genres: [],
            artists: [],
            publishers: [],
            composers: [],
            ISRCCodes: [],
            UPCCodes: [],
            lyrics: [],
            keyWords: [],
            instruments: [],
            moods: [],
            sampleRate: MetadataSampleRateValues.FortyFourPointOne,
            bitDepth: MetadataBitDepthValues.Sixteen,
            type: MetadataType.Advertising,
            vocals: MetadataVocals.BackgroundVocals,
            key: '',
            bpm: ''
          }
        }
      ];
      if (newState.length > 4) {
        const removedState = newState.shift();
        if (removedState) {
          removedState.eventEmitter.emit('clear');
          removedState.eventEmitter.removeAllListeners();
          removedState.waveSurfer?.empty();
          removedState.waveSurfer?.destroy();

          removedState.soloMutedStems?.clear();

          removedState.soloMutedStems = null;
          removedState.playlist = null;
          removedState.waveSurfer = null;
        }
      }
      set({
        playListStates: newState
      });
    }

    return get().playListStates.find(state => state.versionId === versionId);
  },
  initPlaylist: (playlist, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, playlist };
          }
          return state;
        })
      ]
    });
  },

  initWavesurfer: (waveSurfer, songUrl, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, waveSurfer, songUrl };
          }
          return state;
        })
      ]
    });
  }
}));
