export type MetadataAlbumTitle = {
  id: string;
  albumTitle: string;
};

export type MetadataGenre = {
  id: string;
  genre: string;
};

export type MetadataComposer = {
  id: string;
  name: string;
  ownershipRate: number;
};

export type MetadataPublisher = {
  id: string;
  name: string;
  ownershipRate: number;
};

export type MetadataArtist = {
  id: string;
  name: string;
};

export type MetadataISRCCode = {
  id: string;
  code: string;
};

export type MetadataUPCCode = {
  id: string;
  code: string;
};

export type MetadataLyric = {
  id: string;
  lyrics: string;
};

export type MetadataKeyWords = {
  id: string;
  keyWord: string;
};

export type MetadataInstruments = {
  id: string;
  instrument: string;
};

export type MetadataMoods = {
  id: string;
  mood: string;
};

export enum MetadataSampleRateValues {
  FortyFourPointOne = 44.1,
  FortyEight = 48,
  EightyEightPointTwo = 88.2,
  NinetySix = 96,
  HundredAndSeventySixPointFour = 176.4,
  HundredAndNinetyTwo = 192
}

export enum MetadataBitDepthValues {
  Sixteen = 16,
  TwentyFour = 24,
  Thirty = 32
}

export enum MetadataType {
  Instrumental = 'Instrumental',
  Vocals = 'Vocals',
  Soundtrack = 'Soundtrack',
  SoundDesign = 'SoundDesign',
  OneStop = 'OneStop',
  EasyClear = 'EasyClear',
  Advertising = 'Advertising'
}

export enum MetadataVocals {
  None = 'None',
  Choir = 'Choir',
  BackgroundVocals = 'BackgroundVocals',
  Harmonies = 'Harmonies',
  Oohs = 'Oohs',
  Aahs = 'Aahs',
  Whistling = 'Whistling'
}

export type SongMetadata = {
  albumTitles: MetadataAlbumTitle[];
  genres: MetadataGenre[];
  artists: MetadataArtist[];
  composers: MetadataComposer[];
  publishers: MetadataPublisher[];
  ISRCCodes: MetadataISRCCode[];
  UPCCodes: MetadataUPCCode[];
  lyrics: MetadataLyric[];
  keyWords: MetadataKeyWords[];
  instruments: MetadataInstruments[];
  moods: MetadataMoods[];
  sampleRate: MetadataSampleRateValues;
  bitDepth: MetadataBitDepthValues;
  type?: MetadataType;
  vocals?: MetadataVocals;
  description?: string;
};

export type SongMetadataResponse = {
  albumTitles: MetadataAlbumTitle[];
  genres: MetadataGenre[];
  artists: MetadataArtist[];
  composers: MetadataComposer[];
  publishers: MetadataPublisher[];
  ISRCCodes: MetadataISRCCode[];
  UPCCodes: MetadataUPCCode[];
  lyrics: MetadataLyric[];
  sampleRate: { sampleRate: MetadataSampleRateValues };
  bitDepth: { bitDepth: MetadataBitDepthValues };
};
