import { useAuth0 } from '@auth0/auth0-react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import JSZip from 'jszip';
import mime from 'mime';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NotificationType } from '../@types/notifications';
import notificationService from '../services/notificationService';
import trackEvent from '../services/trackService';
import { usePlaylistStore } from '../store/players.store';
import { useSongSelector } from '../store/selectors/song.selector';
import { useBeforeUnload } from '../utils/useBeforeUnload';
import { CancelDownloadingDialog } from './CancelDownloadDialog';
import { DialogCloseButton } from './DialogCloseButton';
// @ts-ignore

type DownloadSongFilesDialogProps = {
  songName: string;
  versionId: string;
  onClose: () => void;
  open: boolean;
};

export default function DownloadSongFilesDialog({ versionId, songName, onClose, open }: DownloadSongFilesDialogProps) {
  useBeforeUnload();
  let isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const { user } = useAuth0();
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === versionId));

  let playlist: any = null;
  let isSongRendered: boolean;
  let isStemsRendered: boolean;
  let stemsCountForLoading: number;
  let loadedStemsCount: number;
  let waveSurfer: any;
  if (playlistState) {
    playlist = playlistState.playlist;
    isSongRendered = playlistState.isSongRendered;
    isStemsRendered = playlistState.isStemsRendered;
    stemsCountForLoading = playlistState.stemsCountForLoading;
    loadedStemsCount = playlistState.loadedStemsCount;
    waveSurfer = playlistState.waveSurfer;
  }
  const { stems, downloadFiles, isDownloadingFiles, song, songParentId, isOriginalStems, getSongWithStems, isCompressedExists } =
    useSongSelector();
  const [downloadStems, setDownloadStems] = useState(!!stems.length);
  const [downloadSong, setDownloadSong] = useState(!!song);
  const [downloadWidth, setDownloadWidth] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (triggerDownload) {
      if ((!song || isSongRendered) && isStemsRendered) {
        handleDownload(downloadWidth > 0);
      } else {
        setDownloadWidth(() => {
          if (stemsCountForLoading === 0) {
            return 33;
          } else {
            return loadedStemsCount === 0 ? 3 : loadedStemsCount * (33 / stemsCountForLoading);
          }
        });
      }
    }
  }, [playlistState, triggerDownload]);

  const handleDownload = async (fakeLoading: boolean = false) => {
    setIsDownloading(true);
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    if (playlist?.tracks?.length === 1) {
      try {
        await new Promise((resolve, reject) => {
          setDownloadWidth(5);
          setIsDownloading(true);

          setTimeout(() => {
            if (signal.aborted) {
              reject(new Error('Download cancelled'));
            } else {
              resolve(null);
            }
          }, 0);
        });
      } catch (error) {
        console.log('Download cancelled');
        setIsDownloading(false);
        onClose();
      }
    }

    // @ts-ignore
    try {
      const zip = new JSZip();
      const filesForDownload: { name: string; url?: string; blob?: Blob }[] = [];
      // determine number of files

      let totalStems: number;

      let percentagePerFile: number;
      let progress: number;

      if (fakeLoading) {
        totalStems = stemsCountForLoading;
        percentagePerFile = 33 / stemsCountForLoading;
        progress = 33;
      } else {
        totalStems = downloadStems ? playlist?.tracks?.length : 0;
        percentagePerFile = playlist?.tracks?.length === 1 ? 45 / totalStems : 50 / totalStems;
        progress = playlist?.tracks?.length === 1 ? 5 : 0;
      }
      let originalStemUrls: string[] = [];
      let originalStemMimes: string[] = [];
      // 50% / totalFiles
      if (downloadStems && playlist.tracks) {
        const originalStems = (await getSongWithStems(undefined, undefined, true))?.stems || [];
        originalStemUrls = originalStems.map(stem => stem.url);
        originalStemMimes = originalStems.map(stem => stem.mime!);

        let downloadPromises: Promise<any>[] = [];
        for (let track of playlist?.tracks) {
          let source = playlist.ac.createBufferSource();
          source.buffer = track.buffer;

          downloadPromises.push(
            new Promise(async (resolve, reject) => {
              let index = playlist?.tracks?.indexOf(track);
              const response = await fetch(originalStemUrls[index]);
              const blob = await response.blob();

              const stemMime = originalStemMimes[index];

              let mimeExtension = mime.getExtension(stemMime ?? originalStemMimes[index] ?? stems?.at(index)?.mime!);

              if ((stemMime ?? originalStemMimes[index] ?? stems?.at(index)?.mime!) === 'audio/flac') {
                mimeExtension = 'flac';
              }

              mimeExtension = mimeExtension === 'mpga' ? 'mp3' : mimeExtension;
              mimeExtension = mimeExtension === 'oga' ? 'ogg' : mimeExtension;

              filesForDownload.push({
                name: track.name.replace(/\.[^/.]+$/, '')! + (mimeExtension?.startsWith('.') ? mimeExtension : `.${mimeExtension}`),
                blob
              });

              progress += percentagePerFile;
              setDownloadWidth(progress);

              setTimeout(() => {
                if (signal.aborted) {
                  reject(new Error('Download cancelled'));
                } else {
                  resolve(null);
                }
              }, 0);
            })
          );
        }

        await Promise.all(downloadPromises);
      }

      if (downloadSong) {
        let mimeExtension = mime.getExtension(song?.mime!);

        if (song?.mime! === 'audio/flac') {
          mimeExtension = 'flac';
        }

        mimeExtension = mimeExtension === 'mpga' ? 'mp3' : mimeExtension;
        mimeExtension = mimeExtension === 'oga' ? 'ogg' : mimeExtension;

        filesForDownload.push({
          name: song?.name.replace(/\.[^/.]+$/, '')! + (mimeExtension?.startsWith('.') ? mimeExtension : `.${mimeExtension}`),
          url: waveSurfer?.getMediaElement().src!
        });
      }

      const downloadingBlobs = filesForDownload.map(async file => {
        let blobData: Blob;

        if (!file.url) {
          blobData = file.blob!;
        } else {
          const response = await fetch(file.url, { signal });
          blobData = await response.blob();
        }

        const fileName = file.name;
        zip.file(fileName, blobData);
      });

      await Promise.all(downloadingBlobs);

      await zip
        .generateAsync({ type: 'blob' }, data => {
          setDownloadWidth(fakeLoading ? data.percent * 0.34 + 66 : data.percent / 2 + 50);
        })
        .then(function (content: any) {
          if (!signal.aborted) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = songName + '.zip';
            link.click();
          }
        });

      !!user && notificationService.createNotification(NotificationType.DownloadSong, songParentId!);

      setIsDownloading(false);
      trackEvent('download', { type: 'song', downloadSong, downloadStems });
      onClose();
    } catch (error) {
      // @ts-ignore
      if (error.name === 'AbortError') {
        console.log('Download cancelled');
      } else {
        console.error('Download failed', error);
      }
      setIsDownloading(false);
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsDownloading(false);
    setDownloadWidth(0);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={() => {
        if (triggerDownload) {
          setShowCancelModal(true);
        } else {
          onClose();
        }
      }}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogCloseButton
        onClose={() => {
          if (triggerDownload) {
            setShowCancelModal(true);
          } else {
            onClose();
          }
        }}
      />
      <DialogTitle
        sx={{
          width: '90%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500'
        }}
      >
        {triggerDownload ? 'Downloading' : 'Download'} song "{songName}"
      </DialogTitle>
      <DialogContent
        sx={{
          position: 'relative'
        }}
        dividers
      >
        <Box
          sx={{
            width: `${downloadWidth}%`,
            height: '100%',
            background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 10
          }}
        ></Box>
        <Stack flexDirection="column">
          {!!song && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadSong}
                onChange={() => setDownloadSong(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Song
              </Typography>
            </Stack>
          )}
          {!!stems.length && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadStems}
                onChange={() => setDownloadStems(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Stems
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => setTriggerDownload(true)}
          disabled={isDownloading || (!downloadSong && !downloadStems)}
          size="small"
          color="info"
          variant="contained"
        >
          Download files
        </Button>
      </DialogActions>
      {showCancelModal && (
        <CancelDownloadingDialog open={showCancelModal} onCancel={handleCancel} onClose={() => setShowCancelModal(false)} />
      )}
    </Dialog>
  );
}
