import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LinkIcon from '@mui/icons-material/Link';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Box, IconButton, Menu, MenuItem, Stack, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FileType } from '../../../@types/files';
import { Song } from '../../../@types/songs';
import { ReactComponent as DeleteIconWidget } from '../../../assets/icons/delete.svg';
import { ReactComponent as CustomDownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as RenameIcon } from '../../../assets/icons/renameIcon.svg';
import { ReactComponent as SharingIcon } from '../../../assets/icons/sharingIcon.svg';
import { ReactComponent as EditArt } from '../../../assets/icons/editArt.svg';
import DeleteSongDialog from '../../../components/DeleteSongDialog';
import DownloadSongFilesDialogSidebar from '../../../components/DownloadSongFilesDialogSidebar';
import SharingDialog from '../../../components/SharingDialog';
import songsService from '../../../services/songsService';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import { useSongSelector } from '../../../store/selectors/song.selector';
import { useUploadFilesSelector } from '../../../store/selectors/uploadFiles.selector';
import { useSidebarStore } from '../../../store/sidebar.store';
import { formatDateMDTime } from '../../../utils/date';
import { AddArtDialog } from './AddArtDialog';

export type FolderDropDownItemProps = {
  song: Song;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
  isSharingFile?: boolean;
  isDefault?: boolean;
  level: number;
};

export default function FolderDropDownItem({
  song,
  setHighlightedSongId,
  highlightedSongId,
  isSharingFile = false,
  isDefault = false,
  level
}: FolderDropDownItemProps) {
  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const { songId } = useParams();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [songIdForDelete, setSongIdForDelete] = useState<string>('');
  const [songIdForRename, setSongIdForRename] = useState<string>('');
  const [songIdForDownload, setSongIdForDownload] = useState<string>('');
  const renameInputRef = useRef<null | HTMLInputElement>(null);
  const { user } = useAuth0();
  const { renameSong, setSongArtBlob } = useFoldersSelector();
  const { uploadArt } = useUploadFilesSelector();
  const { renameSongOnMainSongPage, currentVersion, songVersions, renameVersionWithoutSongTrack, sharedUsers } = useSongSelector();
  const sidebarWidth = useSidebarStore(state => state.sidebarWidth);
  const [showWidgets, setShowWidgets] = useState(false);
  const [isSharingSongDialogOpen, setIsSharingSongDialogOpen] = useState<boolean>(false);
  const [isImageDragged, setIsImageDragged] = useState(false);
  const [isAddArtDialogOpened, setIsAddArtDialogOpened] = useState(false);

  const isAudio = song.fileType === FileType.Audio;
  const url = `/dashboard/${song.folderId}/${song.id}${isAudio ? '' : '/file'}`;

  useEffect(() => {
    renameInputRef.current?.focus();
  }, [songIdForRename]);

  const handleOpenSongMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMobile) return;

    if (isSharingFile && song.accessType !== 'EDITOR') return;
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const handleCloseSongMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenSongDeleteDialog = (songId: string) => {
    handleCloseSongMenu();
    setSongIdForDelete(songId);
  };

  const handleDownloadSongFiles = async (songId: string) => {
    try {
      handleCloseSongMenu();
      setSongIdForDownload(songId);
    } catch (error) {
      console.error('Error handling song file download:', error);
    }
  };
  const handleCloseDownloadDialog = () => {
    setSongIdForDownload('');
  };

  const handleCloseSongDeleteDialog = () => {
    setSongIdForDelete('');
  };

  const handleRename = (songId: string) => {
    handleCloseSongMenu();
    setSongIdForRename(songId);
  };

  const handleShareSong = (songId: string) => {
    handleCloseSongMenu();
    setIsSharingSongDialogOpen(true);
  };

  const handleRenameOnBlur = () => {
    setSongIdForRename('');
  };

  const handleDragOver = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isSharingFile && song.accessType !== 'EDITOR') return;

    const items = e.dataTransfer.items;

    const isImage = Array.from(items).some(file => {
      const mimeType = file.type;

      return mimeType.includes('image/');
    });

    setIsImageDragged(isImage);
  };

  const handleDragLeave = () => {
    if (isSharingFile && song.accessType !== 'EDITOR') return;

    setIsImageDragged(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSharingFile && song.accessType !== 'EDITOR') return;

    const items = e.dataTransfer.items;

    const file = Array.from(items).at(0);

    if (file && file.type.includes('image/')) {
      let fileData = file.getAsFile();

      if (fileData) {
        handleUploadArt(fileData);
      }
    }

    setIsImageDragged(false);
  };

  const handleUploadArt = (image: File) => {
    const imageUrl = URL.createObjectURL(image);
    setSongArtBlob(song.id, song.folderId, imageUrl);
    setShowWidgets(false);
    setIsImageDragged(false);

    uploadArt({ songId: song.id, meta: { mimeType: image.type, originalSize: image.size } }, image);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (
        songId === song.id &&
        currentVersion === songVersions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id
      ) {
        renameSongOnMainSongPage(renameInputRef.current?.value ?? song.name, currentVersion);
      }

      const versions = await songsService.getSongVersions(song.id!);
      const lastVersionId = versions?.at(versions.length - 1)?.id;

      await renameVersionWithoutSongTrack(renameInputRef.current?.value ?? song.name, lastVersionId);
      await renameSong(song.folderId, song.id, renameInputRef.current?.value ?? song.name, user?.name || user?.email);

      handleRenameOnBlur();
    }
  };

  const renderUploadInfo = () => {
    if (song.updatedAt && song.updatedByName) {
      return `Updated ${formatDateMDTime(song.updatedAt, true)} by ${song.updatedByName}`;
    } else if (song.createdAt && song.createdByName) {
      return `Uploaded ${formatDateMDTime(song.createdAt, true)} by ${song.createdByName}`;
    } else if (song.createdByName) {
      return `Uploaded by ${song.createdByName}`;
    }
    return '';
  };

  const getWidgetsWidth = () => {
    if (showWidgets) {
      if (!song.accessType) return 110;

      if (song.accessType === 'EDITOR') return 110;

      if (song.accessType === 'CO_OWNER') return 25;

      return 0;
    }

    return 0;
  };

  return (
    <Stack
      position={'relative'}
      sx={{ cursor: 'pointer' }}
      draggable={!isSharingFile && song.folderId !== 'example'}
      direction="row"
      onDragStart={e => {
        if (isSharingFile) return;

        e.dataTransfer.setData('text/plain', JSON.stringify({ songId: song.id, folderId: song.folderId, songName: song.name }));
      }}
      onMouseEnter={() => {
        if (isMobile) return;

        if (isSharingFile && song.accessType !== 'EDITOR') return;

        setShowWidgets(true);
      }}
      onMouseLeave={() => {
        if (isMobile) return;

        if (isSharingFile && song.accessType !== 'EDITOR') return;

        setShowWidgets(false);
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      gap={2}
      alignItems="center"
    >
      {isAddArtDialogOpened && (
        <AddArtDialog
          open={isAddArtDialogOpened}
          onClose={() => setIsAddArtDialogOpened(false)}
          onCreate={image => handleUploadArt(image)}
          isAlbum={false}
        />
      )}{' '}
      {isSharingSongDialogOpen && (
        <SharingDialog
          songId={song.id}
          folderId={song.folderId}
          isOwner={true}
          isAudio={isAudio}
          sharedSongsUsers={sharedUsers}
          onClose={() => setIsSharingSongDialogOpen(false)}
          open={isSharingSongDialogOpen}
          ownerEmail={song.createdByName || ''}
        />
      )}
      {songIdForDownload && (
        <DownloadSongFilesDialogSidebar
          songName={song?.name ?? ''}
          onClose={() => handleCloseDownloadDialog()}
          open={!!songIdForDownload}
          songId={song.id}
        />
      )}
      {songIdForDelete && (
        <DeleteSongDialog
          songId={songIdForDelete!}
          folderId={song.folderId!}
          songName={song?.name!}
          onClose={() => {
            if (pathname.includes(song.id)) {
              navigate('/dashboard');
            }

            handleCloseSongDeleteDialog();
          }}
          open={!!songIdForDelete}
        />
      )}
      {showWidgets && !songIdForRename && (
        <Stack
          position={'absolute'}
          zIndex={1}
          direction={'row'}
          top={'50%'}
          right={`6px`}
          gap={`3px`}
          sx={{ transform: 'translateY(-50%)' }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {(song.accessType === 'EDITOR' || !song.accessType) && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                handleShareSong(song.id);
              }}
              sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
            >
              <SharingIcon />
            </IconButton>
          )}
          {(song.accessType === 'EDITOR' || song.accessType === 'CO_OWNER' || !song.accessType) && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                handleDownloadSongFiles(song.id);
              }}
              sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
            >
              <CustomDownloadIcon />
            </IconButton>
          )}
          {(song.accessType === 'EDITOR' || !song.accessType) && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                handleRename(song.id);
              }}
              sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
            >
              <RenameIcon />
            </IconButton>
          )}
          {(song.accessType === 'EDITOR' || !song.accessType) && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                handleOpenSongDeleteDialog(song.id);
              }}
              sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
            >
              <DeleteIconWidget />
            </IconButton>
          )}
        </Stack>
      )}
      {(showWidgets || isImageDragged) && !song.artUrl ? (
        <Box
          borderRadius={4}
          sx={{
            border: '2px dashed',
            borderColor: '#2B2B2B',
            background: `linear-gradient(#2B2B2B 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
            display: 'flex',
            width: '48px',
            height: '48px',
            borderRadius: 1,
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            position: 'relative'
          }}
        >
          <IconButton
            size={'small'}
            sx={{ position: 'absolute', bottom: '-1px', right: '-1px' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              setIsAddArtDialogOpened(true);
            }}
          >
            <EditArt />
          </IconButton>
        </Box>
      ) : song.artUrl ? (
        <Box
          borderRadius={1}
          minWidth="48px"
          width="48px"
          height="48px"
          sx={{
            ...((showWidgets || isImageDragged) && {
              border: '2px dashed transparent',
              borderColor: '#2B2B2B',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              userSelect: 'none'
            }),
            position: 'relative',
            background: `url(${song.artUrl}) center/cover no-repeat content-box, linear-gradient(#151515 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`
          }}
        >
          {(showWidgets || isImageDragged) && (
            <IconButton
              size={'small'}
              sx={{ position: 'absolute', bottom: '-1px', right: '-1px' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();

                setIsAddArtDialogOpened(true);
              }}
            >
              <EditArt />
            </IconButton>
          )}
        </Box>
      ) : (
        <Box
          onClick={() => {
            setHighlightedSongId(song.id);
            navigate(url);
          }}
          minWidth="48px"
          width="48px"
          height="48px"
          position="relative"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background:
              highlightedSongId === song.id ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)' : '#4B4B4B66',
            borderRadius: 1
          }}
        >
          {isAudio ? (
            <MusicNoteIcon fontSize="medium" sx={{ position: 'absolute' }} />
          ) : (
            <DescriptionIcon fontSize="medium" sx={{ position: 'absolute' }} />
          )}
        </Box>
      )}
      <Box>
        {songIdForRename ? (
          <TextField
            onClick={() => {
              setHighlightedSongId(song.id);
              navigate(url);
            }}
            size="small"
            defaultValue={song.name}
            inputRef={renameInputRef}
            onKeyDown={handleKeyDown}
            onBlur={handleRenameOnBlur}
            sx={{ fontSize: '14px' }}
          />
        ) : (
          <Box
            onClick={e => {
              if (!isMobile) {
                setHighlightedSongId(song.id);
                navigate(url);
              } else {
                handleOpenSongMenu(e);
              }
            }}
          >
            <Tooltip title={song.name}>
              <Typography
                sx={{
                  '&.MuiTypography-root': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: isMobile
                      ? '210px'
                      : `calc(${sidebarWidth}px - 48px - 32px - 20px  - ${level * 16}px - ${getWidgetsWidth()}px)`,
                    fontSize: '14px'
                  }
                }}
              >
                {song.name}
              </Typography>
            </Tooltip>

            <Tooltip title={renderUploadInfo()}>
              <Typography
                fontSize={12}
                color="rgba(255, 255, 255, 0.7)"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: isMobile
                    ? isDefault
                      ? '210px'
                      : '170px'
                    : `calc(${sidebarWidth}px - 48px - 32px - 20px - ${level * 16}px - ${getWidgetsWidth()}px)`
                }}
              >
                {renderUploadInfo()}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseSongMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {' '}
        {(song.accessType === 'EDITOR' || !song.accessType) && (
          <MenuItem onClick={() => handleShareSong(song.id)}>
            <LinkIcon sx={{ mr: 1 }} />
            Share song
          </MenuItem>
        )}
        {(song.accessType === 'EDITOR' || !song.accessType) && (
          <MenuItem onClick={() => handleRename(song.id)}>
            <DriveFileRenameOutlineIcon fontSize="small" sx={{ mr: 1 }} />
            Rename
          </MenuItem>
        )}{' '}
        {(song.accessType === 'EDITOR' || song.accessType === 'CO_OWNER' || !song.accessType) && (
          <MenuItem
            onClick={() => {
              handleDownloadSongFiles(song.id);
            }}
          >
            <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
            Download
          </MenuItem>
        )}
        {(song.accessType === 'EDITOR' || !song.accessType) && (
          <MenuItem sx={{ color: 'red' }} onClick={() => handleOpenSongDeleteDialog(song.id)}>
            <DeleteIcon fontSize="small" sx={{ mr: 1, color: 'red' }} />
            Delete
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
}
