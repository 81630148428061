import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSongSelector } from '../store/selectors/song.selector';

/** The methods we expose from VideoPlayer to the parent. */
export interface VideoPlayerRef {
  play: () => void;
  pause: () => void;
  adjustTimeBy: (delta: number) => void;
}

interface VideoPlayerProps {
  uploadVideo: (fileList?: FileList) => Promise<void>;
}

const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(({ uploadVideo }, ref) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const { deleteStem, video } = useSongSelector();
  /** When a file is selected, create a blob URL and store it in state. */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    uploadVideo(e.target.files || undefined);
  };

  /** Expose methods to the parent via ref. */
  useImperativeHandle(ref, () => ({
    play: () => {
      videoElementRef.current?.play();
    },
    pause: () => {
      videoElementRef.current?.pause();
    },
    adjustTimeBy: (delta: number) => {
      if (videoElementRef.current) {
        videoElementRef.current.currentTime += delta;
      }
    }
  }));

  /** Remove the video by resetting `videoSrc` to null. */
  const handleRemoveVideo = async () => {
    await deleteStem(video?.id!);
  };
  return (
    <div style={{ marginTop: '1rem' }}>
      {!video && <input type="file" accept="video/*" onChange={handleFileChange} />}

      {video && (
        <div style={{ marginTop: '1rem' }}>
          <video ref={videoElementRef} src={video.url} width={400} controls style={{ display: 'block', marginBottom: '0.5rem' }} />
          <button onClick={handleRemoveVideo}>Remove Video</button>
        </div>
      )}
    </div>
  );
});

export default VideoPlayer;
