import axios from 'axios';
import axiosRetry from 'axios-retry';
import { UploadArtResponse, UploadFileRequest, UploadFileResponse } from '../@types/uploadFile';

const uploadClient = axios.create({
  withCredentials: false
});

axiosRetry(uploadClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true
});

const submitFileToS3 = async (
  file: File,
  s3Meta: UploadFileResponse,
  songId: string,
  onProgress: (progress: number, songId: string) => void,
  onEstimated?: (estimatedTime: number, songId: string) => void
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const formData = new FormData();

      for (const key in s3Meta?.fields) {
        formData.append(key, s3Meta?.fields[key] ?? '');
      }

      formData.append('Content-Type', file.type);
      formData.append('file', file);
      await uploadClient.post(s3Meta.url, formData, {
        onUploadProgress: progressEvent => {
          onProgress(Math.floor(progressEvent.progress! * 100), songId);
          if (onEstimated) {
            onEstimated(progressEvent.estimated!, songId);
          }
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Encoding': 'gzip'
        }
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const submitArtToS3 = async (file: File, s3Meta: UploadArtResponse) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const formData = new FormData();

      for (const key in s3Meta?.fields) {
        formData.append(key, s3Meta?.fields[key] ?? '');
      }

      formData.append('Content-Type', file.type);
      formData.append('file', file);
      await uploadClient.post(s3Meta.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const zippingPromises: { [key: number]: { resolve: (file: File) => void; reject: (error: Error) => void } } = {};
const concurrentZipTasks = 5;
const workerPool = new Array(concurrentZipTasks).fill(null).map(() => {
  const worker = new Worker(new URL('../workers/zipWorker.ts', import.meta.url));
  worker.onmessage = e => {
    const { zippedFile, taskId } = e.data;
    zippingPromises[taskId].resolve(zippedFile);
    delete zippingPromises[taskId];
  };
  worker.onerror = (err: any) => {
    const { error, taskId } = err.data!;
    zippingPromises[taskId].reject(error);
    delete zippingPromises[taskId];
  };
  return worker;
});
let currentZipTaskId = 0;

const gzipFile = async function (file: File): Promise<File> {
  return new Promise<File>((resolve, reject) => {
    workerPool[currentZipTaskId % concurrentZipTasks].postMessage({ file, taskId: currentZipTaskId });
    zippingPromises[currentZipTaskId] = { resolve, reject };
    currentZipTaskId++;
  });
};

const uploadService = { submitFileToS3, gzipFile, submitArtToS3 };

export default uploadService;
